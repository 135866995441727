<template>
  <v-container fluid>
    <v-row>
      <v-col
        md="6"
        cols="12"
        class="pr-1"
      >
        <validation-observer
          ref="observer"
          v-slot="{ invalid }"
        >
          <v-row>
            <v-col
              cols="12"
              class="pb-2"
            >
              <base-material-card
                color="primary"
                icon="mdi-key"
                :title="title"
                inline
                class="px-5 py-4 ml-0"
              >
                <v-row>
                  <v-col
                    cols="12"
                    class="pb-0"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      rules="required"
                    >
                      <v-text-field
                        label="Nome"
                        outlined
                        :error-messages="errors"
                        v-model="nome"
                        class="pt-5"
                      />
                    </validation-provider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="12"
                    class="pb-0"
                  >
                    <alert-consultas-customizadas
                      :versaoBdgdMaisRecente="versaoBdgdMaisRecente"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="12"
                    class="pb-0"
                  >
                    <v-card class="mt-0">
                      <v-toolbar flat>
                        <v-tabs grow>
                          <v-tab>Consulta SQL</v-tab>
                        </v-tabs>
                      </v-toolbar>
                      <validation-provider
                        v-slot="{ errors }"
                        rules="required"
                      >
                        <v-textarea
                          class="mx-4 ta-padding-c2"
                          v-model="consultaSQL"
                          rows="16"
                          hint="Não insira ';' (ponto e vírgula) no final da query"
                          no-resize
                          clearable
                          background-color="#EEEEEE"
                          placeholder="Escreva aqui sua consulta SQL"
                          :error-messages="errors"
                        />
                      </validation-provider>
                    </v-card>
                  </v-col>
                </v-row>
              </base-material-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-btn
                color="primary"
                min-width="100"
                style="float: right"
                class="mt-n5"
                @click="salvar()"
                :disabled="invalid"
                :loading="loading"
              >
                Salvar
              </v-btn>
            </v-col>
          </v-row>
        </validation-observer>
      </v-col>
      <v-col
        md="6"
        cols="12"
        class="pl-1"
      >
        <v-row>
          <v-col
            cols="12"
            class="pb-2"
          >
            <base-material-card
              color="primary"
              icon="mdi-table-eye"
              title="Estrutura das Bases de Dados"
              inline
              class="px-5 py-4 ml-0"
            >
              <estrutura-bases-dados-liberadas class="mt-8" />
            </base-material-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import dayjs from 'dayjs';
import BdgdVersionsService from '@/services/BdgdVersionsService';
import RelatoriosService from '@/services/RelatoriosService';
import routes from '@/store/modules/routes';

export default {
  components: {
    AlertConsultasCustomizadas: () =>
      import('@/components/general/AlertConsultasCustomizadas'),
    EstruturaBasesDadosLiberadas: () =>
      import('@/components/general/GeneralEstruturaBasesDadosLiberadas')
  },
  data: () => ({
    nome: '',
    consultaSQL: '',
    loading: false,
    ultimaDataBaseBdgd: null,
    ultimaVersaoBdgd: null,
    versoesBdgds: null
  }),
  computed: {
    title() {
      return this.$route.params.id
        ? 'Editando Relatório Customizado'
        : 'Adicionar Relatório Customizado';
    },
    companyId() {
      return this.$store.getters.getCompanyId;
    },
    userSelectedCompanyBdgdVersion() {
      return this.$store.getters.getUserSelectedCompanyBdgdVersion;
    },
    versaoBdgdMaisRecente() {
      if (this.userSelectedCompanyBdgdVersion) {
        return this.userSelectedCompanyBdgdVersion;
      }
      if (!this.versoesBdgds) {
        return null;
      }
      return this.versoesBdgds.find((bdgd) => {
        const dataBase = dayjs(bdgd.data_registro).format('YYYY-MM-01');
        return (
          dataBase === this.ultimaDataBaseBdgd &&
          bdgd.versao === this.ultimaVersaoBdgd
        );
      });
    },
    consultaSQLPadrao() {
      let exemplos = '';
      if (this.versaoBdgdMaisRecente && this.versaoBdgdMaisRecente.id) {
        exemplos += `Exemplo: select * from ucbt where bdgd_versao_id = ${this.versaoBdgdMaisRecente.id}\n`;
        exemplos += `Exemplo com joins: 
select 
  a.cod_id ucat, 
  b.cod_id medidor, 
  a.pac
from 
  ucat a
  inner join eqme b on 
    a.cod_id = b.uc_ug and 
    a.bdgd_versao_id = b.bdgd_versao_id and 
    a.pac <> b.pac
where
  a.bdgd_versao_id = ${this.versaoBdgdMaisRecente.id} 
`;
      }
      if (this.ultimaDataBaseBdgd && this.ultimaVersaoBdgd) {
        exemplos += `Exemplo: select * from view_bar where mes_dados = '${this.ultimaDataBaseBdgd}' and bdgd_versao = '${this.ultimaVersaoBdgd}'\n`;
      }
      if (this.companyId) {
        exemplos += `Exemplo: select * from view_ais_consulta where company_id = ${this.companyId}\n`;
      }
      return exemplos;
    }
  },
  created() {
    this.getUltimaDataBaseBdgd();
    this.$route.params.id && this.getRelatorio(this.$route.params.id);
  },
  methods: {
    getRelatorio(id) {
      RelatoriosService.getRelatorio(id)
        .then((response) => {
          this.nome = response.data.data.nome;
          this.consultaSQL = response.data.data.codigo_sql;
        })
        .catch(() =>
          this.$toast.error('Erro ao recuperar relatorio customizado.', '', {
            position: 'topRight'
          })
        );
    },
    getUltimaDataBaseBdgd() {
      if (this.userSelectedCompanyBdgdVersion) {
        const { data_registro } = this.userSelectedCompanyBdgdVersion;
        this.ultimaDataBaseBdgd = dayjs(data_registro).format('YYYY-MM-DD');
        return;
      }
      BdgdVersionsService.getUltimaDataBase(this.companyId)
        .then(({ data }) => {
          this.ultimaDataBaseBdgd = data ? data : null;
          if (!this.$route.params.id) this.consultaSQL = this.consultaSQLPadrao;
        })
        .catch((err) => {
          this.$toast.error('Erro ao buscar a última data base da BDGD.', '', {
            position: 'topRight'
          });
          console.error('Erro:', err);
        });
    },
    getUltimaVersaoBdgd() {
      if (this.userSelectedCompanyBdgdVersion) {
        const { versao } = this.userSelectedCompanyBdgdVersion;
        this.ultimaVersaoBdgd = versao;
        if (!this.$route.params.id) this.consultaSQL = this.consultaSQLPadrao;
        return;
      }
      BdgdVersionsService.getBdgdVersion(
        this.companyId,
        this.ultimaDataBaseBdgd
      )
        .then((response) => {
          this.ultimaVersaoBdgd = response ? response : null;
          if (!this.$route.params.id) this.consultaSQL = this.consultaSQLPadrao;
        })
        .catch((err) => {
          this.$toast.error('Erro ao buscar a última versão da BDGD.', '', {
            position: 'topRight'
          });
          console.error('Erro:', err);
        });
    },
    getVersoesBdgdsImportadas() {
      BdgdVersionsService.getBdgdVersions(
        this.companyId,
        this.ultimaDataBaseBdgd
      )
        .then((response) => {
          this.versoesBdgds = response ? response : null;
          if (!this.$route.params.id) this.consultaSQL = this.consultaSQLPadrao;
        })
        .catch((err) => {
          this.$toast.error('Erro ao buscar a última versão da BDGD.', '', {
            position: 'topRight'
          });
          console.error('Erro:', err);
        });
    },
    salvar() {
      this.loading = true;
      let sql = this.consultaSQL.trim();
      let data = {
        id: this.$route.params.id ? this.$route.params.id : null,
        nome: this.nome,
        codigo_sql: sql.slice(-1) === ';' ? sql.slice(0, sql.length - 1) : sql
      };
      RelatoriosService.salvarCustomizado(data)
        .then(() => {
          this.$toast.success('Relatório customizado salvo com sucesso.', '', {
            position: 'topRight'
          });
          this.$router.replace({ name: routes.state.appRoutes['RELATORIOS_HISTORICO'] });
        })
        .catch(() => {
          this.$toast.error('Erro ao salvar relatório customizado.', '', {
            position: 'topRight'
          });
        })
        .finally(() => (this.loading = false));
    }
  },
  watch: {
    ultimaDataBaseBdgd() {
      if (this.companyId && this.ultimaDataBaseBdgd) {
        this.getUltimaVersaoBdgd();
      }
    },
    ultimaVersaoBdgd() {
      if (this.companyId && this.ultimaVersaoBdgd) {
        this.getVersoesBdgdsImportadas();
      }
    }
  }
};
</script>

<style>
.ta-padding-c2 .v-text-field__slot {
  padding: 8px;
}
.ta-padding-c2 textarea {
  font-size: 11px !important;
  font-weight: bold !important;
}
</style>
